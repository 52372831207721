import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import ButtonContrast from 'components/elements/ButtonContrast'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const StyledMedias = styled.div`
  h3 {
    font-weight: ${props => props.theme.font.weight.xl};
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.third};
  }
`

const Content = styled(ParseContent)`
  font-family: ${props => props.theme.font.family.secondary};
  color: ${props => props.theme.color.text.third};
`

const Download = ``

const Medias = ({ className }) => {
  const {
    articles: {
      nodes: articles
    }
  } = useStaticQuery(graphql`{
    articles: allWordpressPost(sort: {fields: date, order: DESC}, filter: {categories: {elemMatch: {wordpress_id: {eq: 3}}}}) {
      nodes {
        acf {
          title
          description
          link_text
          link_url
          papaer {
            url {
              localFile {
                url
              }
            }
          }
        }
      }
    }
  }`)


  return (
    <StyledMedias className={`container ${className ? `${className}` : ``}`}>
      {articles.map(a => (
        <div className="row pt-4 pb-5">
          <div className="col-lg-12">
            <h3 className="pb-2">{a.acf.title}</h3>
            <Content content={a.acf.description} />
            {a.acf.link_url ? (
              <ButtonContrast newPage className="mt-3" to={a.acf.link_url}>
              {a.acf.link_text}
            </ButtonContrast>
            ) : (
              <ButtonContrast  newPage className="mt-3" to={a.acf.papaer.url.localFile.url}>
              {a.acf.link_text}
            </ButtonContrast>
            )}
          </div>
        </div>
      ))}
    </StyledMedias>
  )
}

export default Medias